// extracted by mini-css-extract-plugin
export var section = "homestyle-module--section--1VgTA";
export var cardDiv = "homestyle-module--card-div--2W5ry";
export var cardTwo = "homestyle-module--card-two--nGgAs";
export var cardFive = "homestyle-module--card-five--3boJe";
export var programCardImg = "homestyle-module--program-card-img--3pdpW";
export var descriptionHeight = "homestyle-module--description-height--5y4mg";
export var sliderDiv = "homestyle-module--slider-div--37r48";
export var sliderImg = "homestyle-module--slider-img--1tjSJ";
export var cardOne = "homestyle-module--card-one--szn_G";
export var cardThree = "homestyle-module--card-three--1fiUU";
export var cardFour = "homestyle-module--card-four--RlHRv";
export var cardSix = "homestyle-module--card-six--GV7va";
export var libraryColOneDiv = "homestyle-module--library-col-one-div--3oaJb";